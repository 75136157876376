import React from 'react'
import Header from '../Header/Header'
import './Hero.css'
import hero_image from '../../assets/hero_image.png'
import hero_image_back from '../../assets/hero_image_back.png'
import Heart from '../../assets/heart.png'
import Calories from '../../assets/calories.png'
import {motion} from 'framer-motion'
import NumberCounter  from 'number-counter'
const Hero = () => {
    const transition = {type:'spring', duration:3}
    const mobile = window.innerWidth<=768 ? true : false
  return (
   <div className='hero' >
        <div className="blur blur-hero"/>
        {/* left hero */}
        <div className='left-h' >
            <Header/>
             {/* the best ad */}
            <div className='the-best-ad' >
                <motion.div
                    initial={{left:mobile?"150px":"238px"}}
                    whileInView={{left:'8px'}}
                    transition={{...transition, type:'tween'}}

                />
                <span>The best fitness club in the town</span>
            </div>
            {/* hero heading */}
            <div className='hero-text' >
                <div>
                    <span className='stroke-text' >Shape </span>
                    <span>Your</span>
                </div>
                <div>
                    <span>Ideal Body </span>
                    
                </div>
                <div>
                    <span>In here we will help you o shape and build your ideal body and live up your life to fullest</span>
                </div>
            </div>
            {/* figures */}
            <div className='figures' >
                <div>
                    <span>
                        <NumberCounter end={140} start={100} delay={4} preFix="+" />
                    </span>
                    <span>Expert Coaches</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={978} start={100} delay={4} preFix="+" />
                        </span>
                    <span>Member joined</span>
                </div>
                <div>
                    <span>
                    <NumberCounter end={50} start={10} delay={4} preFix="+" />
                    </span>
                    <span>fitness program</span>
                </div>
                

            </div>
            {/* hero buttons */}
            <div className='hero-button' >
                <buttons className='btn'>Get started</buttons>
                <buttons className='btn'>Learn More</buttons>
            </div>
        </div>
        {/* right hero */}
        <div className='right-h' >
            <button className='btn' >Join Now</button>
            <motion.div className='heart-rate' 
                transition={transition}
                initial={{right:"1rem"}}
                whileInView={{right:"4rem"}}

            > 
                <img src={Heart} alt="" />
                <span>Heart Rate </span><span>115 bpm</span>
            </motion.div>
            <img src={hero_image} alt="" className='hero-image' />
            <motion.img src={hero_image_back} alt="" className='hero-image-back' 
                 transition={transition}
                 initial={{right:"11rem"}}
                 whileInView={{right:"20rem"}}
            />
            {/* caleroies */}
            <motion.div className='calories'
                 transition={transition}
                 initial={{right:"37rem"}}
                 whileInView={{right:"28rem"}}
            >
                <img src={Calories} alt="" />
                <div>
                <span>Calories Burned</span>
                <span> 220 kcal</span>
                </div>
            </motion.div>
        </div>
   </div>
  )
}

export default Hero