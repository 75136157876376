import React from 'react'
import { useRef } from 'react'
import emailjs from '@emailjs/browser'
 
import './join.css'
const Join = () => {
  const form = useRef()  
  const sendEmail = (e) => {
    e.preventDefault();
    // emailjs.sendForm('YOUR_SERVICE_ID', 'YOUR_TEMPLATE_ID', form.current, 'YOUR_PUBLIC_KEY')
    //   .then((result) => {
    //       console.log(result.text);
    //   }, (error) => {
    //       console.log(error.text);
    //   });
    
  };
  return (
    <div
        className='Join'
        id="join-us"
    >
        <div className="left-j">
        <hr />
              <div>
                <span className='stroke-text' >READY TO</span>
                <span> LEVEL UP</span>
              </div>
              <div>
                <span>Your Body</span>
                <span  className='stroke-text' > WITH US</span>
            </div>
        </div>
        <div className="right-j">
            <form ref={form} className="email-container" onSubmit={sendEmail} >
                <input type="email"  name="user_email" placeholder='Enter Yout Email Address' />
                <button className="btn btn-join">Join Now</button>
            </form>
        </div>
    </div>
  )
}

export default Join