import React, { useState } from "react";
import "./Header.css";
import Logo from "../../assets/logo.png";
import Bars from "../../assets/bars.png";
import {Link} from 'react-scroll'
const Header = () => {
  const header_list = ["Home", "Programs", "Why Us", "Plans", "Testimonials"];
  const mobile = window.innerWidth <= 768 ? true : false;
  const [menuOpened, setMenuOpened] = useState(false);
  return (
    <div className="header">
      <img src={Logo} alt="" className="logo" />
      {menuOpened == false && mobile == true ? (
        <div
            style={{
                backgroundColor:'var(--appColor)',
                padding:'0.5rem',
                borderRadius:"5px",
                cursor:'pointer'
            }}
            onClick={()=>{
                setMenuOpened(true)
            }}
        >
          <img src={Bars} alt="" className="logo" style={{
            width:'1.5rem',
            height:'1.5rem',
          }}  />
        </div>
      ) : (
        <ul className="header-menu">
          {header_list.map((label, index) => (
            <li ><Link
                to={label}
                spy={true}
                smooth={true}
                onClick={()=>{
                    setMenuOpened(false)}}
            >{label}</Link></li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Header;
 